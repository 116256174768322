import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import logo from './logo.svg';
import './App.css';

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          S.G. LJUNGBERG
        </p>
      </header>
  </div>
  )
}

function Privacy() {
  return (
    <div className="App">
      <div class="Privacy">
        <div class="Privacy-child">
          <h2>Privacy Policy</h2>
          <p>We don't collect nor store any of your data. The microphone is used in the application in order to identify the program/film that you are watching but no recordings are made.</p>
          <p>If you have any questions regarding our handling of data, please contact <a href="mailto:support@sgljungberg.se">our support</a>.</p>
          <p>S.G.Ljungberg AB 2020</p>
        </div>
      </div>
    </div>
  )
}

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
